import Axios from 'axios'
import qs from 'qs'
import * as Sentry from '@sentry/react'

export const ADMIN_TOKEN_STORAGE_KEY = 'feathers-jwt'
export const CLIENT_OTP_TOKEN_STORAGE_KEY = 'token'

export const initializeAxiosInstance = () => {
  Axios.defaults.baseURL = process.env.REACT_APP_API
  Axios.defaults.paramsSerializer = (params) => qs.stringify(params)

  try {
    if (window.localStorage.getItem(ADMIN_TOKEN_STORAGE_KEY)) {
      Axios.defaults.headers.common[
        'Authorization'
      ] = `${window.localStorage.getItem(ADMIN_TOKEN_STORAGE_KEY)}`
    }

    if (window.localStorage.getItem(CLIENT_OTP_TOKEN_STORAGE_KEY)) {
      Axios.defaults.headers.common[
        'Authorization'
      ] = `${window.localStorage.getItem(CLIENT_OTP_TOKEN_STORAGE_KEY)}`
    }
  } catch (e: any) {
    Sentry.captureException(e)

    /**
     * If the error is caused by incognito mode,
     * redirect the user to the incognito page.
     */
    if (e instanceof DOMException && (e.message.includes('cookie') || e.message.includes('Access is denied'))) {
      if (window.location.pathname !== '/error/incognito') {
        window.location.href = '/error/incognito'
      }
    }
  }

  return Axios
}

let axios = initializeAxiosInstance()

export default axios

/**
 * Reinitialize the stored Axios instance because
 * of an incoming new token.
 * 
 * @param token JWT token
 * @param replace Set to true if the current token stored on localstorage should be overwritten
 * @returns Axios instance
 */
export function reinitializeAxiosInstance(token: string, replace?: boolean) {
  if (replace) {
    if (window.localStorage.getItem(ADMIN_TOKEN_STORAGE_KEY)) {
      window.localStorage.setItem(ADMIN_TOKEN_STORAGE_KEY, token)
    }

    if (window.localStorage.getItem(CLIENT_OTP_TOKEN_STORAGE_KEY)) {
      window.localStorage.setItem(CLIENT_OTP_TOKEN_STORAGE_KEY, token)
    }
  }

  Axios.defaults.headers.common[
    'Authorization'
  ] = token

  axios = Axios
  return axios
}