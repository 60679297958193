import { SignedDocumentInterface } from "../interfaces/SignedDocument";
import { AGENT_TYPE } from "./AgentTypeManager";
import { ClientInterface } from "./Clients";

export interface IUser {
  _id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  accountType?: string;

  isExisting?: boolean;
  createdAt?: string;

  deactivatedOn?: Date;

  customSubAgentRegistrationLink?: string;
  agentType?: AGENT_TYPE | string;
  commissionPercentage?: number;
  specialCommissionPercentage?: number;
  contract?: SignedDocumentInterface;
  phoneNumber?: string;
  country?: string;
  isVipAgent?: boolean;

  hubspotUserID?: string;
  assignedClients?: ClientInterface[];
  assignedClientsCount?: number;

  facebookPixelId?: string;
  googleAnalyticsId?: string;
  googleTagManagerId?: string;

  uplineUserId?: string;
  uplineUser?: IUser;
  webhookUrl?: string;

  everee?: {
    workerId: string;
    status: string;
    createdAt: Date;
    completedAt: Date;
  };

  emailSubscriptions?: string[];
}

class User implements IUser {
  _id: string;
  email: string;
  firstName: string | undefined;
  lastName: string | undefined;
  accountType: string | undefined;
  customSubAgentRegistrationLink: string | undefined;
  agentType?: AGENT_TYPE | string | undefined;
  commissionPercentage?: number | undefined;
  specialCommissionPercentage?: number | undefined;
  phoneNumber: string | undefined;
  country?: string | undefined;
  isVipAgent?: boolean | undefined;
  hubspotUserID: string | undefined;
  assignedClients?: ClientInterface[];
  facebookPixelId?: string | undefined;
  googleAnalyticsId?: string | undefined;
  googleTagManagerId?: string | undefined;
  webhookUrl?: string | undefined;
  uplineUserId?: string | undefined;
  uplineUser?: IUser | undefined;
  contract?: SignedDocumentInterface;
  everee?: {
    workerId: string;
    status: string;
    createdAt: Date;
    completedAt: Date;
  };
  createdAt?: string;
  emailSubscriptions?: string[];

  constructor(data: IUser) {
    Object.assign(this, data);
    this._id = data._id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.accountType = data.accountType;
    this.customSubAgentRegistrationLink = data.customSubAgentRegistrationLink;
    this.agentType = data.agentType;
    this.commissionPercentage = data.commissionPercentage;
    this.specialCommissionPercentage = data.specialCommissionPercentage;
    this.phoneNumber = data.phoneNumber;
    this.country = data.country;
    this.isVipAgent = data.isVipAgent;
    this.hubspotUserID = data.hubspotUserID;
    this.facebookPixelId = data.facebookPixelId;
    this.googleAnalyticsId = data.googleAnalyticsId;
    this.googleTagManagerId = data.googleTagManagerId;
    this.webhookUrl = data.webhookUrl;
  }
}

export default User;
