import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import auth from '@feathersjs/authentication-client'

/**
 * Todo: Update this to use environmnet variables for the server
 */
const socket = io(process.env.REACT_APP_API || 'http://localhost:3030', { transports: ['websocket'], upgrade: false });
const feathersClient = feathers();

// Set up Socket.io client with the socket
feathersClient.configure(socketio(socket));
feathersClient.configure(auth())

export default feathersClient