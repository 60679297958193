import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

// import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
   Sentry.init({
      dsn: "https://7d4b2c712c644349926435c6a1afd107@o1001081.ingest.sentry.io/6489231",
      replaysOnErrorSampleRate: 1.0,
      integrations: [
         new Sentry.Replay()
      ],
      allowUrls: [
         'https://portal.ertcexpress.com',
         'https://cdn.ertcexpress.com',
      ],
   });
}

ReactDOM.render(
   <React.StrictMode>
      <BrowserRouter>
         <App />
      </BrowserRouter>
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
